const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Nama Program',
    dataIndex: 'nama_program',
  },
  {
    title: 'Region Program',
    dataIndex: 'region_program',
  },
  {
    title: 'Kode Toko',
    dataIndex: 'kode_toko',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'nama_toko',
  },
  {
    title: 'Area Toko',
    dataIndex: 'area_toko',
  },
  {
    title: 'Region Toko',
    dataIndex: 'region_toko',
  },
  {
    title: 'No Ref Penukaran',
    dataIndex: 'no_ref_penukaran',
  },
  {
    title: 'Kode Reward',
    dataIndex: 'kode_reward',
  },
  {
    title: 'Nama Reward',
    dataIndex: 'nama_reward',
  },
  {
    title: 'Nilai Redeem Reward',
    dataIndex: 'nilai_redeem_reward',
  },
  {
    title: 'Nilai Unit Ditukar [A]',
    dataIndex: 'nilai_unit_ditukar',
  },
  {
    title: 'Jumlah Unit [B]',
    dataIndex: 'jumlah_unit',
  },
  {
    title: 'Total Digunakan [A*B]',
    dataIndex: 'total_digunakan',
  },
  {
    title: 'Nama Unit',
    dataIndex: 'nama_unit',
  },
  {
    title: 'Skala Konversi (Khusus "Cairkan Uang") [C]',
    dataIndex: 'skala_konversi',
  },
  {
    title: 'Uang Didapatkan (Khusus "Cairkan Uang") [A*B*C]',
    dataIndex: 'uang_didapatkan',
  },
  {
    title: 'Tanggal Penukaran',
    dataIndex: 'tanggal_penukaran',
  },
]
export default acolumns
